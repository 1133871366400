import loadable from '@loadable/component';
import './App.css';
import { Container } from 'react-bootstrap';
import { Suspense } from 'react';
import Loading from './common/loader/loading'
import Header from './header/header'
import Menu from './menu/menu'
import Banner from './banner/banner'

const Partners = loadable(() => import('./partners/partners'));
const About = loadable(() => import('./about/about'));

const Clients = loadable(() => import('./clients/clients'));
const Footer = loadable(() => import('./footer/footer'));
const Services = loadable(() => import('./services/services'));
const Contact = loadable(() => import('./contact/contact'));

function App() {
  return (
    <div className="App">
      <Container>
        <Suspense fallback={<Loading />}>
          <Header />

          <Menu />
          <Banner />
          <Services />
          <About />
          <Clients />
          <Partners />
          <Contact />

          <Footer />
        </Suspense>
      </Container>

    </div>
  );
}

export default App;
