import './header.css';
import Phone from '../assets/images/Phone.svg';
import Linkedin from '../assets/images/LinkedIn.svg';
import { Col, Form, Row, Image, Button } from 'react-bootstrap';

function Header() {
    return (
        <div className="header">
            <Row>
                <Col xs={12} md={12}>
                    <Form.Group>
                        <Image src={Phone} alt="Phone" />
                        <Form.Label className='phone-no' style={{ marginRight: "15px", marginLeft: "5px" }}> <a className='phone-no' href="tel:+355696389264">+355 69 63 89 264</a></Form.Label>
                        <Form.Label style={{cursor: "pointer"}} onClick={() => window.open("https://www.linkedin.com/company/treetech-solution", "_blank")}>
                            <Image src={Linkedin} alt="Linkedin" />

                            <Button variant="link" className="btn-link" target="_blank" href="">Follow Us</Button>
                        </Form.Label>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
}

export default Header;
