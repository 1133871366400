import './menu.css';
import logo from '../assets/images/TreeTech-Logo.svg';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';

function Menu() {
    return (
        <div className="menu">
            <Navbar expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#" className='logo-img' >
                        <Image src={logo} alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="" className='menu-item'>Home</Nav.Link>
                            <span className='divider'></span>
                            <Nav.Link href="#services" className='menu-item'>Services</Nav.Link>
                            <span className='divider'></span>
                            <Nav.Link href="#clients" className='menu-item'>Clients</Nav.Link>
                            <span className='divider'></span>
                            <Nav.Link href="#partners" className='menu-item'>Partners</Nav.Link>
                            <span className='divider'></span>
                            <Nav.Link href="#about" className='menu-item'>About us</Nav.Link>
                            <span className='divider'></span>
                            {/* <Nav.Link href="#team" className='menu-item'>Team</Nav.Link>
                            <span className='divider'></span> */}
                            <Nav.Link href="#contact" className='menu-item'>Contact us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Menu;
