import BannerImage from '../assets/images/BannerImage.svg';
import { Col, Row, Image } from 'react-bootstrap';

function Banner() {
    return (
        <Row xs={12} md={12}>
            <Col>
                <Image src={BannerImage} style={{width: "100%"}} />
            </Col>
        </Row>
    );
}

export default Banner;
